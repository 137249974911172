// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-table__th1, .products-table__td1 {
    text-align: center;
    width: 40px;
}

.products-table__th2, .products-table__td2 {
    width: 45%;
}`, "",{"version":3,"sources":["webpack://./src/styles/products.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".products-table__th1, .products-table__td1 {\n    text-align: center;\n    width: 40px;\n}\n\n.products-table__th2, .products-table__td2 {\n    width: 45%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
