import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Відео"
const ProductVideo = (props) => {

    const [videoList, setVideoList] = useState((props.data != undefined) ? props.data.videoList : []);
    const [video, setVideo] = useState("");

    const data_response = {
        video: videoList,
        active: 'on',
    };

    useEffect(() => {
        if (props.MyData) {
            setVideoList(props.MyData.video);
        }
    }, [props.MyData]);

    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Посилання на YouTube</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput6" value={video == null ? "" : video} onChange={(e) => setVideo(e.target.value)} autoComplete="off" />
                </div>
                <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductVideo;